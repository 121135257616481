.NavBar{
	padding: 0 20px;
	position: sticky;
	top: 0;
	border-bottom: 1px solid var(--borderColor);
	overflow: hidden;
	background-color: var(--bodyBG);
}

.NavBar .navContainer{
	background-color: var(--bodyBG);
	color: var(--mainTextColor);
	height: 55px;
	flex-wrap: wrap;
}

.NavBar .childrenBox{
	padding: 0 10px;
}

.NavBar .titleBox{
	color: var(--neutralAccentColor);
	font-weight: 550;
	padding: 10px;
	padding-left: 0px;
	font-size: 18pt;
}

.NavBar .controls{
	position: absolute;
	right: 0;
	padding: 10px;
}

.NavBar .controls .icon{
	color: var(--neutralAccentColor);
	font-size: 22pt;
}

.NavBar .MenuButton.button{
	padding: 8px 15px;
}
