.LinearIssue{
	--cellWidth: 120px;
	--expanderWidth: 30px;
	--projectWidth: 180px;
	padding: 10px;
	border-bottom: 1px solid var(--borderColor);
	background-color: var(--bodyBG);
}

.LinearIssue .expander{
	width: var(--expanderWidth);
	font-size: 13pt;
	color: #c0babc;
	cursor: pointer;
	border: none;
	padding-right: 10px;
}

.LinearIssue .cell{
	width: var(--cellWidth);
}

.LinearIssue .ProjectCell{
	width: var(--projectWidth);
}

.LinearIssue .title{
	width: calc(100% - var(--cellWidth) - var(--cellWidth) - var(--expanderWidth) - var(--projectWidth));
}

.LinearIssue .statusCell{
	padding: 3px 8px;
	color: white;
	font-weight: bold;
	border-radius: var(--smallRadius);
	width: 100%;
}

.LinearIssue .desc{
	padding: 20px 10px;
	overflow: hidden;
	height: fit-content;
	padding-left: 30px;
}

.LinearIssue:hover{
	filter: brightness(98%);
}

.mobile .LinearIssue .title{
	padding-left: 30px;
	padding-top: 10px;
	width: 100%;
}

.mobile .LinearIssue .StatusCell,
.mobile .LinearIssue .statusBadge{
	width: fit-content;
}

.mobile .LinearIssue .PriorityCell{
	padding-left: 10px;	
}

.mobile .LinearIssue .ProjectCell{
	width: 100%;
	padding-left: 30px;
	padding-top: 15px;
	font-weight: bold;
}
